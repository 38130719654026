/* Updated styles for a softer and more formal design */

.chat-page {
  display: flex;
  justify-content: center;
  align-items: center; /* Center vertically */
  height: 100vh; /* Full viewport height */
  padding-top: 5vh; /* Adjust this value to move the chat container a little bit above the center */
  background-color: #f0f0f0; /* Maintain background color */
  overflow-x: auto; /* Allow horizontal scrolling */
  overflow-y: auto; /* Allow vertical scrolling */
  width: 100vw; /* Full viewport width */
  margin-top: -70px;
}

.chat-container {
  background-color: #ffffff;
  width: 600px;
  max-width: 90%; /* Ensures responsiveness */
  height: 500px;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  padding: 20px;
  position: relative;
}

h2 {
  text-align: center;
  margin-bottom: 15px;
  font-size: 1.5rem;
  color: #333333;
}

.product-container, .status-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.product-box, .status-box {
  margin-left: 10px;
  padding: 8px 12px;
  border-radius: 8px;
  font-weight: 600;
  font-size: 0.9rem;
}

.product-box {
  background-color: #007bff;
  color: #ffffff;
}

.status-box{
	background-color: #007bff;
	color: #fff;
}

.status-box.available {
  background-color: #6c757d;
  color: #ffffff;
}

.status-box.completed {
  background-color: #28a745;
  color: #ffffff;
}

.status-box.processing {
  background-color: #17a2b8;
  color: #ffffff;
}

.status-box.answered-by-merchant {
  background-color: #ff9800;
  color: #ffffff;
}

.messages-container {
  flex: 1;
  overflow-y: auto;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: #f9f9f9;
}

.message {
  background-color: #e9ecef;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 10px;
  color: #333333;
  font-size: 0.9rem;
}

.message span:first-child {
  font-weight: bold;
  margin-right: 5px;
}

.input-container {
  display: flex;
  align-items: center;
}

input[type="text"] {
  flex: 1;
  padding: 12px;
  border: 1px solid #ced4da;
  border-radius: 8px;
  font-size: 0.9rem;
  margin-right: 10px;
}

input[type="text"]:focus {
  border-color: #80bdff;
  outline: none;
  box-shadow: 0 0 4px rgba(0, 123, 255, 0.25);
}

button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 8px;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

button:hover {
  background-color: #0056b3;
}

/* Important note styling */
.important-note {
  position: absolute;
  top: 20px; /* Restored top position */
  left: 20px;
  background-color: #fff;
  border: 1px solid #f00;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  z-index: 1000; /* Ensure it's on top */
}

.important-note:hover .note-content {
  display: block;
}

.note-icon {
  color: #dc3545;
  font-size: 20px;
  font-weight: bold;
}

.note-content {
  display: none;
  position: absolute;
  top: 37px;
  left: 5px;
  background-color: #fff;
  border: 1px solid #dc3545;
  border-radius: 8px;
  padding: 15px;
  width: 280px;
  z-index: 1000;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.note-content p {
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px;
}

.note-content ol {
  padding-left: 20px;
  color: #555555;
}

.note-content ol li {
  margin-bottom: 8px;
}

/* Adjust chat container width for mobile devices */
@media (max-width: 600px) {
  .chat-container {
    width: 90%; /* Adjust width to fit within the viewport */
    height: 80%; /* Adjust height if needed */
  }
}