.photo-gallery-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f4f4f4;
    padding: 60px 20px; /* Adds padding to move content down and avoid scrollbars */
    min-height: 100vh; /* Ensures the wrapper fills the screen height */
    box-sizing: border-box; /* Includes padding in the height calculation */
}

.gallery-title-container {
    text-align: center;
    background-color: #17a2b8;
    border: 2px solid #ddd;
    padding: 15px 30px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 80%; /* Ensures the title container doesn't span the entire width */
    max-width: 800px;
    margin-bottom: 40px; /* Space between the title and the gallery */
}

.gallery-title {
    font-size: 28px; /* Slightly larger font for formality */
    font-weight: 700; /* Bolder font weight */
    color: #333;
    margin: 0;
    font-family: 'Times New Roman', serif; /* More formal font */
}

.gallery {
    display: flex;
    gap: 20px;
    margin-top: 200px; /* Adds space between the title and the gallery */
}

.photo {
    flex: 1;
    max-width: 200px;
    overflow: hidden;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    position: relative;
    transition: transform 0.3s ease, z-index 0.3s ease;
    text-align: center;
}

.photo img {
    width: 100%;
    height: auto;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.photo:hover {
    transform: scale(2.8);
    z-index: 10;
}

.photo:hover img {
    object-fit: contain;
    width: 100%;
    height: auto;
}

.photo-label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
}
