/* orders.css */

.orders-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 150px;
}

.order-box- {
  position: relative; /* Ensure that child elements are positioned relative to this container */
  border: 1px solid #ccc;
  padding: 20px;
  padding-top: 40px; /* Add padding at the top to make space for the icon */
  margin: 10px;
  width: 300px;
  text-align: center;
  background-color: #f9f9f9;
  border-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Product container styling */
.product-container- {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.product-box- {
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #17a2b8;
  color: #fff;
  font-weight: bold;
}

.request-order-button {
  background-color: #007bff;
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
}

.request-order-button:hover {
  background-color: #0056b3;
}

.request-order-button:active {
  background-color: #004494;
}

.status-container- {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.status-box- {
  margin-left: 10px;
  padding: 5px 10px;
  border-radius: 5px;
  background-color: #17a2b8;
  color: #fff;
  font-weight: bold;
}

.status-box-.available {
  background-color: #9582b2;
  color: #fff;
}

.status-box-.delivered {
  background-color: #5cb85c;
  color: #fff;
}

.status-box-.processing {
  background-color: #17a2b8;
  color: #fff;
}

.loading-icon {
  margin-top: 10px;
  width: 50px; /* Adjust size as needed */
  height: 50px; /* Adjust size as needed */
}

/* Styling for the larger square box when status is 'Delivered' */
.delivered-box- {
  width: 300px; /* Increased size */
  height: 250px; /* Increased size */
  border: 3px solid #007bff; /* Distinct border color */
  background-color: #fff; /* White background */
  margin-top: 10px;
  margin-left: -5px;
  display: inline-block;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: auto; /* Enable vertical scrolling */
  padding: 3px; /* Add padding to space out content from the borders */
  text-align: left; /* Align text to the left */
  line-height: 1.5; /* Adjust line height for decreased spacing */
  font-size: 18px; /* Adjust font size as needed */
  color: #333; /* Text color if needed */
  white-space: pre; /* Preserve white space, but do not wrap lines */
}

.delivery-info-box {
  margin-top: 16px;
}

.delivered-box- pre {
  white-space: pre; /* Preserve white space inside delivered-box- */
  word-wrap: normal; /* Disable word wrap inside delivered-box- */
}

.checkmark-icon {
  width: 50px; /* Adjust the size as needed */
  height: 50px; /* Adjust the size as needed */
  margin-left: 10px; /* Space between status box and icon */
}

.important-note-icon- {
  position: absolute;
  top: 0px; /* Adjust as needed */
  left: 0px; /* Adjust as needed */
  width: 60px; /* Adjust size as needed */
  height: 60px; /* Adjust size as needed */
  z-index: 10; /* Ensure the icon is above other elements */
  cursor: pointer; /* Indicate that the icon is interactive */
}

.tooltip {
  position: absolute;
  top: -35px; /* Position below the icon */
  left: 0; /* Align with the icon */
  background-color: #fff; /* Dark background for the tooltip */
  color: #dc5860; /* White text color */
  padding: 8px; /* Space around the text */
  height: 20px;
  border: 1px solid #f00;
  border-radius: 4px; /* Rounded corners */
  white-space: nowrap; /* Prevent text from wrapping */
  visibility: hidden; /* Hide tooltip by default */
  opacity: 0; /* Make tooltip invisible by default */
  transition: opacity 0.3s; /* Fade-in effect */
  z-index: 20; /* Ensure tooltip is above other elements */
  width: 500px; /* Adjust width as needed */
}

.important-note-container:hover .tooltip {
  visibility: visible; /* Show tooltip on hover */
  opacity: 1; /* Make tooltip visible */
}

.timestamps-box- {
  position: absolute;
  top: 0;
  right: -330px; /* Adjust based on border thickness */
  width: 300px; /* Adjust size as needed */
  padding: 10px;
  background-color: #f8f8f8;
  border-top: 1px solid #ccc;
  border-right: 1px solid #ccc;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: left;
  line-height: 1.5;
}

.requested-at-box- {
  display: inline-block;
  background-color: #17a2b8;
  color: #fff;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-weight: bold;
  z-index: 1;
  width: max-content;
}

.delivered-at-box- {
  display: inline-block;
  background-color: #5cb85c;
  color: #fff;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-weight: bold;
  z-index: 1;
  width: max-content;
}

.estimated-delivery-time-container {
  margin-top: 20px;
  font-size: 14px;
}

.estimated-delivery-time-box {
  margin-top: 10px;
  padding: 4px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #17a2b8;
  font-weight: bold;
}

.having-an-issue-button {
  color: white;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 0px;
}

.modal-overlay- {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.modal- {
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.modal- h2 {
  margin-top: 0;
}

.modal- textarea {
  width: 100%;
  height: 100px;
  margin-bottom: 10px;
}

.modal- button {
  margin-right: 10px;
}

.modal- button:last-child {
  margin-right: 0;
}

.modal-input-group- {
  margin-bottom: 15px;
}

.modal-input-group- label {
  display: block;
  margin-bottom: 5px;
}

.order-id-input- {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.message-textarea- {
  width: 100%;
  height: 100px;
  padding: 8px;
  box-sizing: border-box;
  margin-bottom: 10px;
}

.modal-button-group- button {
  margin-right: 10px;
}

.chat-interface- {
  display: flex;
  flex-direction: column;
  height: 500px; /* Fixed height for the chat interface */
}

.chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.close-ticket-button {
  background-color: #d9534f;
  color: #fff;
  border: none;
  padding: 8px 12px;
  border-radius: 4px;
  cursor: pointer;
}

.close-ticket-button:hover {
  background-color: #c9302c;
}

.chat-messages- {
  flex: 1; /* Allows this section to grow and fill available space */
  overflow-y: auto; /* Vertical scroll if needed */
  margin-bottom: 15px;
  padding: 10px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.chat-message- {
  display: block; /* Ensures each message is treated as a block element */
  text-align: left; /* Ensure text is left-aligned */
  margin-bottom: 10px; /* Add spacing between messages */
  padding: 8px; /* Add padding around the message */
  background-color: #f1f1f1; /* Set background color for the message bubble */
  border-radius: 8px; /* Round the corners of the message bubble */
  max-width: 100%; /* Limit the width of the message bubble to container width */
  word-wrap: break-word; /* Allow long words to break to the next line */
  overflow-wrap: break-word; /* Ensure overflow is handled correctly */
  white-space: pre-wrap; /* Preserve whitespace and allow text to wrap */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.chat-message-.customer {
  text-align: left;
}

.chat-user- {
  font-weight: bold;
  margin-right: 5px;
}

.chat-input-container- {
  margin-top: 10px;
}

.chat-input- {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: none; /* Prevent resizing */
  box-sizing: border-box; /* Include padding and border in element's total width and height */
}

.order-id-container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.order-id-label {
  font-weight: bold;
  margin-right: 10px;
}

.order-id-box {
  font-weight: bold;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 100px;
  background-color: #17a2b8;
}

.additional-info-text {
  border: 2px solid #007bff; /* Blue border around the text */
  padding: 10px; /* Space between the text and the border */
  margin-top: 10px; /* Space above the text block */
  border-radius: 20px; /* Rounded corners for a smoother look */
  background-color: #f9f9f9; /* Optional: light blue background for better contrast */
}

.link-text {
  color: blue;
  text-decoration: underline;
}

.link-text:hover {
  color: darkblue;
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-box {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  text-align: center;
  width: 20%;
  font-weight: bold;
  font-size: 1.1em;
  color: #333;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

/* Adjust for smaller screens */
@media screen and (max-width: 768px) {
  .popup-box {
    width: 80%; /* Make the box wider for better readability */
    font-size: 1em; /* Adjust font size for smaller screens */
  }
}

@media screen and (max-width: 480px) {
  .popup-box {
    width: 90%; /* Almost full width for very small screens */
    padding: 15px; /* Reduce padding to fit smaller screens */
    font-size: 0.9em; /* Slightly smaller font size */
  }
}

.close-popup-button {
  background: #007bff;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 4px;
}

.close-popup-button:hover {
  background: #0056b3;
}